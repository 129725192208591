<template>
  <v-form ref="form" class="mx-2" method="post" enctype="multipart/form-data">
    <v-container v-if="isCreatedCorrectly"
      id="REPORT"
      fluid
      tag="section"
    >
        <v-col cols="12">
          <base-material-card
            color="white"
            iconcolor="darkred"
            icon="mdi-file-chart"
            :title="$t('report')"
            class="px-5 py-3"
          >
            <v-tabs v-model="tab" background-color="transparent" grow>
              <v-tab 
                v-for="item in reportTabs" 
                :key="item"
                @change="getTabSelected(item)"
                >
                {{ item }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in reportTabs" :key="item" eager>
                <v-card v-if="item == 'Fornitori'" flat>
                  <v-row style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px">
                    <v-col cols="2">
                      <v-radio-group
                        v-model="radiosFornitori"
                        mandatory
                        @change="onRadioChangedFornitori"
                      >
                        <v-radio label="Budget per Fornitore" value="1"></v-radio>
                        <v-radio label="Residui per Fornitore" value="2" v-show="false"></v-radio>
                        <v-radio label="Spese Manutenzione per Fornitore" value="3"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="10">
                    <!-- Budget per Fornitore - 1 -->
                    <v-card v-if="radiosFornitori == 1">
                        <v-row style="margin-top: 20px; margin-left: 10px; margin-right: 20px; margin-bottom 10px;" dense>
                          <v-col cols="4">
                              <v-text-field
                                v-model="f1_annoDa"
                                :label="$t('annoDa')"
                                type="number"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                              <v-text-field
                                v-model="f1_annoAl"
                                :label="$t('annoAl')"
                                type="number"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              v-model="f1_fornitore"
                              :items="eFornitori"
                              :label="$t('fornitore')"
                              item-text="cRagioneSociale"
                              item-value="id"
                              return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              v-model="f1_ente"
                              :items="eEnti"
                              :label="$t('ente')"
                              item-text="cDescrizione"
                              item-value="id"
                              return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                          </v-col>
                          <v-col cols="4">
                          </v-col>
                        </v-row>
                    </v-card>

                    <!-- Residui per Fornitore - 2 -->
                    <v-card v-if="radiosFornitori == 2">
                      <v-row style="margin-top: 20px; margin-left: 10px; margin-right: 20px; margin-bottom 10px;" dense>
                        <v-col cols="4">
                            <v-text-field
                              :label="$t('anno')"
                              type="number"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-select
                              :items="eFornitori"
                              :label="$t('fornitore')"
                              item-text="cRagioneSociale"
                              item-value="id"
                              return-object
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select
                            v-model="enteSel"
                            :items="eEnti"
                            :label="$t('ente')"
                            item-text="cDescrizione"
                            item-value="id"
                            return-object
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-card>

                    <!-- Spese Manutenzione per Fornitore - 3 -->
                    <v-card v-if="radiosFornitori == 3">
                      <v-row style="margin-top: 20px; margin-left: 10px; margin-right: 20px; margin-bottom 10px;" dense>
                        <v-col cols="4">
                          <v-text-field
                            v-model="f3_anno"
                            min="1990"
                            max="2040"
                            :label="$t('anno')"
                            type="number"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-select
                            v-model="f3_id_stato_buono"
                            :items="eFornitoriSpeseManutTipol"
                            :label="$t('buoniConFatturaReport')"
                            item-text="cDescrizione"
                            item-value="id"
                            return-object
                          >
                          </v-select>                         
                        </v-col>
                        <v-col cols="4">
                            <v-select
                              v-model="f3_ente"
                              :items="eEnti"
                              :label="$t('ente')"
                              item-text="cDescrizione"
                              item-value="id"
                              return-object
                            >
                            </v-select>
                        </v-col>
                      </v-row>
                    </v-card>
                    
                    </v-col>
                  </v-row>
                </v-card>
                
                <v-card v-if="item == 'Buoni'" flat>
                  <v-row style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px">
                    <v-col cols="2">
                      <v-radio-group
                        v-model="radiosBuoni"
                        mandatory
                        @change="onRadioChangedBuoni"
                      >
                        <v-radio label="Interventi di Manutenzione" value="1"></v-radio>
                        <v-radio label="Elenco Buoni" value="2"></v-radio>
                        <v-radio label="Lista Interventi" value="3"></v-radio>
                        <v-radio label="Conteggio Buoni" value="4"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="10">
                    <!-- Interventi di Manutenzione - 1 -->
                    <v-card v-if="radiosBuoni == 1">
                      <v-row  dense>
                        <v-row style="margin-top: 20px; margin-left: 10px; margin-right: 20px; margin-bottom 10px;" dense>
                          <v-col cols="4">
                              <v-text-field
                                v-model="b1_data_dal"
                                :label="$t('dataBuonoDal')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                              <v-text-field
                                v-model="b1_data_al"
                                :label="$t('dataBuonoAl')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              v-model="b1_fornitore"
                                :items="eFornitori"
                                :label="$t('fornitore')"
                                item-text="cRagioneSociale"
                                item-value="id"
                                return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              v-model="veicAttrezSel"
                              :items="eVeicoliAttrez"
                              :label="$t('veicoloAttrezzatura')"
                              @change="onVeicoliAttrezzaturaChange"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-select v-if="veicAttrezSel=='Veicolo'"
                              v-model="b1_veicolo"
                                :items="eVeicoli"
                                :label="$t('veicolo')"
                                item-text="selezioneVeicolo"
                                item-value="id"
                                return-object
                            >
                            </v-select>
                            <v-select v-if="veicAttrezSel=='Attrezzatura'"
                                v-model="b1_attrez"
                                :items="eAttrezzature"
                                :label="$t('attrezzatura')"
                                item-text="cSigla"
                                item-value="id"
                                return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="2">
                          </v-col>
                          <v-col cols="4">
                            <v-checkbox
                              v-model="b1_soloBuoniConFattura"
                              :label="$t('buoniConFattura')"
                              hide-details
                            >
                            </v-checkbox>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              v-model="b1_tipoIntervento"
                                :items="t_tipointerventi"
                                :label="$t('complessivoRiparato')"
                                item-text="cDescrizione"
                                item-value="id"
                                return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              v-model="b1_ente"
                              :items="eEnti"
                              :label="$t('ente')"
                              item-text="cDescrizione"
                              item-value="id"
                              return-object
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-row>
                    </v-card>
                    <!-- Elenco Buoni - 2 -->
                    <v-card v-if="radiosBuoni == 2">
                      <v-row  dense>
                        <v-row style="margin-top: 20px; margin-left: 10px; margin-right: 20px; margin-bottom 10px;" dense>
                          <v-col cols="4">
                              <v-text-field
                                v-model="b2_data_dal"
                                :label="$t('dataBuonoDal')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                              <v-text-field
                                v-model="b2_data_al"
                                :label="$t('dataBuonoAl')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                                v-model="b2_fornitore"
                                :items="eFornitori"
                                :label="$t('fornitore')"
                                item-text="cRagioneSociale"
                                item-value="id"
                                return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              v-model="veicAttrezSel"
                              :items="eVeicoliAttrez"
                              :label="$t('veicoloAttrezzatura')"
                              @change="onVeicoliAttrezzaturaChange"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-select v-if="veicAttrezSel=='Veicolo'"
                                v-model="b2_veicolo"
                                :items="eVeicoli"
                                :label="$t('veicolo')"
                                item-text="selezioneVeicolo"
                                item-value="id"
                                return-object
                            >
                            </v-select>
                            <v-select v-if="veicAttrezSel=='Attrezzatura'"
                                v-model="b2_attrez"
                                :items="eAttrezzature"
                                :label="$t('attrezzatura')"
                                item-text="cSigla"
                                item-value="id"
                                return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                                v-model="b2_stato_buono"
                                :items="eBuoniSelezionati"
                                :label="$t('buoniSelezionati')"
                                item-text="cDescrizione"
                                item-value="id"
                                return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              v-model="b2_ente"
                              :items="eEnti"
                              :label="$t('ente')"
                              item-text="cDescrizione"
                              item-value="id"
                              return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                          </v-col>

                          <v-col cols="4">
                          </v-col>
                          <v-col cols="4">

                          </v-col>
                          <v-col cols="4">

                          </v-col>
                        </v-row>
                      </v-row>
                    </v-card>
                    <!-- Lista Interventi - 3 -->
                    <v-card v-if="radiosBuoni == 3">
                      <v-row  dense>
                        <v-row style="margin-top: 20px; margin-left: 10px; margin-right: 20px; margin-bottom 10px;" dense>
                          <v-col cols="4">
                              <v-text-field
                                v-model="b3_data_dal"
                                :label="$t('dataBuonoDal')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                              <v-text-field
                                v-model="b3_data_al"
                                :label="$t('dataBuonoAl')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                                v-model="b3_fornitore"
                                :items="eFornitori"
                                :label="$t('fornitore')"
                                item-text="cRagioneSociale"
                                item-value="id"
                                return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              v-model="veicAttrezSel"
                              :items="eVeicoliAttrez"
                              :label="$t('veicoloAttrezzatura')"
                              @change="onVeicoliAttrezzaturaChange"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-select v-if="veicAttrezSel=='Veicolo'"
                                v-model="b3_veicolo"
                                :items="eVeicoli"
                                :label="$t('veicolo')"
                                item-text="selezioneVeicolo"
                                item-value="id"
                                return-object
                            >
                            </v-select>
                            <v-select v-if="veicAttrezSel=='Attrezzatura'"
                                v-model="b3_attrez"
                                :items="eAttrezzature"
                                :label="$t('attrezzatura')"
                                item-text="cSigla"
                                item-value="id"
                                return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="2">
                          </v-col>
                          <v-col cols="4">
                            <v-select
                                v-model="b3_tipoIntervento"
                                :items="t_tipointerventi"
                                :label="$t('complessivoRiparato')"
                                item-text="cDescrizione"
                                item-value="id"
                                return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              v-model="b3_ente"
                              :items="eEnti"
                              :label="$t('ente')"
                              item-text="cDescrizione"
                              item-value="id"
                              return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                          </v-col>
                        </v-row>
                      </v-row>
                    </v-card>
                    <!-- Conteggio Buoni - 4 -->
                    <v-card v-if="radiosBuoni == 4">
                      <v-row  dense>
                        <v-row style="margin-top: 20px; margin-left: 10px; margin-right: 20px; margin-bottom 10px;" dense>
                          <v-col cols="4">
                              <v-text-field
                                v-model="b4_data_dal"
                                :label="$t('dataBuonoDal')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                              <v-text-field
                                v-model="b4_data_al"
                                :label="$t('dataBuonoAl')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                                v-model="b4_stato_buono"
                                :items="eBuoniSelezionati"
                                :label="$t('buoniSelezionati')"
                                item-text="cDescrizione"
                                item-value="id"
                                return-object
                            >
                            </v-select>
                          </v-col>

                          <v-col cols="4">
                            <v-select
                              v-model="b4_ente"
                              :items="eEnti"
                              :label="$t('ente')"
                              item-text="cDescrizione"
                              item-value="id"
                              return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                                v-model="b4_servizio"
                                :items="eServizi"
                                :label="$t('Servizi')"
                                item-text="cDescrizione"
                                item-value="id"
                                return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                          </v-col>
                        </v-row>
                      </v-row>
                    </v-card>
                    </v-col>
                  </v-row>
                </v-card>

                <v-card v-if="item == 'Scadenze'" flat>
                  <v-row style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px">
                    <v-col cols="2">
                      <v-radio-group
                        v-model="radiosScadenze"
                        mandatory
                        @change="onRadioChangedScadenze"
                      >
                        <v-radio label="Scadenze Aperte" value="1"></v-radio>
                        <v-radio label="Lista Scadenze Collaudi" value="2"></v-radio>
                        <v-radio label="Lista Scadenze Generali" value="3"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="10">
                    <!-- Scadenze Aperte - 1 -->
                    <v-card v-if="radiosScadenze == 1">
                      <v-row style="margin-top: 20px; margin-left: 10px; margin-right: 20px; margin-bottom 10px;" dense>
                          <v-col cols="4">
                              <v-text-field
                                v-model="s1_data_dal"
                                :label="$t('dataScadenzaDal')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                              <v-text-field
                                v-model="s1_data_al"
                                :label="$t('dataScadenzaAl')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              v-model="s1_ente"
                              :items="eEnti"
                              :label="$t('ente')"
                              item-text="cDescrizione"
                              item-value="id"
                              return-object
                            >
                            </v-select>
                          </v-col>                        
                      </v-row>                        
                    </v-card>

                    <!-- Lista Scadenze Collaudi - 2 -->
                    <v-card v-if="radiosScadenze == 2">
                      <v-row style="margin-top: 20px; margin-left: 10px; margin-right: 20px; margin-bottom 10px;" dense>
                          <v-col cols="4">
                              <v-text-field
                                v-model="s2_data_dal"
                                :label="$t('dataScadenzaDal')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                              <v-text-field
                                v-model="s2_data_al"
                                :label="$t('dataScadenzaAl')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              v-model="s2_ente"
                              :items="eEnti"
                              :label="$t('ente')"
                              item-text="cDescrizione"
                              item-value="id"
                              return-object
                            >
                            </v-select>
                          </v-col>                        
                      </v-row>
                    </v-card>

                    <!-- Lista Scadenze Generali - 3 -->
                    <v-card v-if="radiosScadenze == 3">
                      <v-row style="margin-top: 20px; margin-left: 10px; margin-right: 20px; margin-bottom 10px;" dense>
                          <v-col cols="4">
                              <v-text-field
                                v-model="s3_data_dal"
                                :label="$t('dataScadenzaDal')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                              <v-text-field
                                v-model="s3_data_al"
                                :label="$t('dataScadenzaAl')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              v-model="s3_ente"
                              :items="eEnti"
                              :label="$t('ente')"
                              item-text="cDescrizione"
                              item-value="id"
                              return-object
                            >
                            </v-select>
                          </v-col>
                      </v-row>
                    </v-card>
                    
                    </v-col>
                  </v-row>
                </v-card>

                <v-card v-if="item == 'Veicoli'" flat>
                  <v-row style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px">
                    <v-col cols="3">
                      <v-radio-group
                        v-model="radiosVeicoli"
                        mandatory
                        @change="onRadioChangedVeicoli"
                      >
                        <v-radio label="Riepilogo Costi Automezzo" value="1"></v-radio>
                        <v-radio label="Riepilogo Costi Classe veicolo" value="2" v-show="false"></v-radio>
                        <v-radio label="Riepilogo Costi Capitoli di Spesa" value="3"></v-radio>
                        <v-radio label="Riepilogo Consumi Automezzi" value="4"></v-radio>
                        <v-radio label="Riepilogo Consumi Carburante" value="5"  v-show="false"></v-radio>
                        <v-radio label="Riepilogo Consumi Capitoli di Spesa" value="6"></v-radio>
                        <v-radio label="Riepilogo Consumi Anno" value="7" v-show="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="9">
                      <!-- Riepilogo Costi Automezzo - 1 -->
                      <v-card v-if="radiosVeicoli == 1">
                        <v-row style="margin-top: 20px; margin-left: 10px; margin-right: 20px; margin-bottom 10px;" dense>
                          <v-col cols="4">
                              <v-text-field
                                v-model="v1_data_dal"
                                :label="$t('dataVeicoloDal')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                              <v-text-field
                                v-model="v1_data_al"
                                :label="$t('dataVeicoloAl')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-select v-if="veicAttrezSel=='Veicolo'"
                                v-model="v1_veicolo"
                                :items="eVeicoli"
                                :label="$t('veicolo')"
                                item-text="selezioneVeicolo"
                                item-value="id"
                                return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              v-model="v1_ente"
                              :items="eEnti"
                              :label="$t('ente')"
                              item-text="cDescrizione"
                              item-value="id"
                              return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                          </v-col>
                          <v-col cols="4">
                          </v-col>
                        </v-row>
                          
                      </v-card>

                      <!-- Riepilogo Costi Classe veicolo - 2 -->
                      <v-card v-if="radiosVeicoli == 2">
                        <v-row style="margin-top: 20px; margin-left: 10px; margin-right: 20px; margin-bottom 10px;" dense>
                          <v-col cols="4">
                              <v-text-field
                                v-model="v2_data_dal"
                                :label="$t('dataVeicoloDal')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                              <v-text-field
                                v-model="v2_data_al"
                                :label="$t('dataVeicoloAl')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-checkbox
                              v-model="v2_verifica_rif"
                              :label="$t('verificaRifornimento')"
                              hide-details
                              @change="checkStorico($event)"
                            >
                              >
                            </v-checkbox>
                          </v-col>       
                        </v-row>             
                      </v-card>

                      <!-- Riepilogo Costi Capitoli di Spesa - 3 -->
                      <v-card v-if="radiosVeicoli == 3">
                        <v-row style="margin-top: 20px; margin-left: 10px; margin-right: 20px; margin-bottom 10px;" dense>
                          <v-col cols="4">
                              <v-text-field
                                v-model="v3_data_dal"
                                :label="$t('dataVeicoloDal')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                              <v-text-field
                                v-model="v3_data_al"
                                :label="$t('dataVeicoloAl')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                             <v-select
                              v-model="v3_ente"
                              :items="eEnti"
                              :label="$t('ente')"
                              item-text="cDescrizione"
                              item-value="id"
                              return-object
                            >
                            </v-select>
                          </v-col>       
                        </v-row>     
                      </v-card>

                      <!-- Riepilogo Consumi Automezzi - 4 -->
                      <v-card v-if="radiosVeicoli == 4">
                          <v-row style="margin-top: 20px; margin-left: 10px; margin-right: 20px; margin-bottom 10px;" dense>
                          <v-col cols="4">
                              <v-text-field
                                v-model="v4_data_dal"
                                :label="$t('dataVeicoloDal')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                              <v-text-field
                                v-model="v4_data_al"
                                :label="$t('dataVeicoloAl')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-select v-if="veicAttrezSel=='Veicolo'"
                                v-model="v4_veicolo"
                                :items="eVeicoli"
                                :label="$t('veicolo')"
                                item-text="selezioneVeicolo"
                                item-value="id"
                                return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              v-model="v4_ente"
                              :items="eEnti"
                              :label="$t('ente')"
                              item-text="cDescrizione"
                              item-value="id"
                              return-object
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-checkbox
                              v-model="v4_converti"
                              :label="$t('veicoli.converti')"
                              hide-details
                            >
                            </v-checkbox>
                          </v-col>
                          <v-col cols="4">
                          </v-col>
                        </v-row>
                      </v-card>

                      <!-- Riepilogo Consumi Carburante - 5 -->
                      <v-card v-if="radiosVeicoli == 5">
                          
                      </v-card>

                      <!-- Riepilogo Consumi Capitoli di Spesa - 6 -->
                      <v-card v-if="radiosVeicoli == 6">
                        <v-row style="margin-top: 20px; margin-left: 10px; margin-right: 20px; margin-bottom 10px;" dense>
                          <v-col cols="4">
                              <v-text-field
                                v-model="v6_data_dal"
                                :label="$t('dataVeicoloDal')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                              <v-text-field
                                v-model="v6_data_al"
                                :label="$t('dataVeicoloAl')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                             <v-select
                              v-model="v6_ente"
                              :items="eEnti"
                              :label="$t('ente')"
                              item-text="cDescrizione"
                              item-value="id"
                              return-object
                            >
                            </v-select>
                          </v-col>       
                        </v-row>   
                      </v-card>

                      <!-- Riepilogo Consumi Anno - 7 -->
                      <v-card v-if="radiosVeicoli == 7">
                          
                      </v-card>

                    </v-col>     
                  </v-row>
                </v-card>

                <v-card v-show="false" v-if="item == 'Attrezzature'" flat>
                  <v-row style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px">
                  </v-row>
                </v-card>

                <v-card v-if="item == 'Rifornimenti'" flat>
                  <v-row style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px">
                  </v-row>
                </v-card>

                <v-card v-if="item == 'Notifiche'" flat>
                  <v-row style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px">
                    <v-col cols="3">
                      <v-radio-group
                        v-model="radiosNotifiche"
                        mandatory
                        @change="onRadioChangedNotifiche"
                      >
                        <v-radio label="Elenco Notifiche" value="1"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="9">
                      <!-- Elenco Notifiche - 1 -->
                      <v-card v-if="radiosNotifiche == 1">
                          
                      </v-card>       
                    </v-col>             
                  </v-row>
                </v-card>

                <v-card v-if="item == 'Prenotazioni'" flat>
                  <v-row style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px">
                    <v-col cols="3">
                      <v-radio-group
                        v-model="radiosPrenotazioni"
                        mandatory
                        @change="onRadioChangedPrenotazioni"
                      >
                        <v-radio label="Elenco Prenotazioni" value="1"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="9">
                      <!-- Elenco Prenotazioni - 1 -->
                      <v-card v-if="radiosPrenotazioni == 1">
                        <v-row style="margin-top: 20px; margin-left: 10px; margin-right: 20px; margin-bottom 10px;" dense>
                          <v-col cols="4">
                              <v-text-field
                                v-model="p1_data_dal"
                                :label="$t('dataPrenotDal')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                              <v-text-field
                                v-model="p1_data_al"
                                :label="$t('dataPrenotAl')"
                                type="date"
                                min="1990-01-01"
                                max="2050-12-31"
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="4">
                          </v-col>       
                          <v-col cols="4">
                          </v-col>       
                        </v-row>                               
                      </v-card>       
                    </v-col>             
                  </v-row>
                </v-card>

              </v-tab-item>
            </v-tabs-items>

            <v-divider class="mx-4"></v-divider>
            
            <v-row>
              <v-col style="margin-top: 10px" align="end">
                <v-btn
                  tile
                  color="darkred"
                  @click="onGeneraReportClick"
                >
                  <v-icon left> mdi-plus-circle-outline </v-icon>
                  {{ $t("generaReport") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mx-4" style="margin-top: 10px"></v-divider>
            <v-row
              style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
            >
              <v-alert
                id="alertDettaglio"
                shaped
                :value="isAlertDetailVisible"
                :type="alertType"
                dismissible
              >
                {{ messaggioAlert }}
              </v-alert>
            </v-row>
          </base-material-card>
        </v-col>

    </v-container>
  </v-form>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

import Utils from "@/utils/Utils";
import UtilsDate from "@/utils/UtilsDate";

import { environment } from "@/environment/environment";
import i18n from "@/i18n";

import TipologicheService from "@/service/TipologicheService";
import FornitoriService from "@/service/FornitoriService";
import AttrezzatureService from "@/service/AttrezzatureService";
import VeicoliService from "@/service/VeicoliService";
import NotificheService from "@/service/NotificheService";
import EntiService from "@/service/EntiService";
import ReportService from "@/service/ReportService";
import ExcelService from "@/service/ExcelService";

@Component
export default class Report extends Vue {

  //reportTabs = ["Fornitori", "Buoni", "Scadenze", "Veicoli", "Attrezzature", "Rifornimenti"];
  reportTabs = ["Fornitori", "Buoni", "Scadenze", "Veicoli", "Notifiche", "Prenotazioni"];

  eVeicoliAttrez = ["Veicolo", "Attrezzatura"];

  eBuoniSelezionati = [ 
    {
      id: -1,
      cDescrizione: "Tutti",
    },
    {
      id: 1,
      cDescrizione: "Bozza",
    },
    {
      id: 2,
      cDescrizione: "Emesso"
    }, 
    {
      id: 3,
      cDescrizione: "Fatturato"
    }, 
  ]
    

  
  t_storicoDotaz= [ 
    { 
      id: 1,
      cDescrizione: "Capitolo"
    },
    { 
      id: 2,
      cDescrizione: "Classe di spesa sintetico"
    },
    { 
      id: 3,
      cDescrizione: "Classe di spesa analitico"
    },
  ];

  eFornitoriSpeseManutTipol = [ 
    { 
      id: 1,
      cDescrizione: "Tutti"
    },
    { 
      id: 2,
      cDescrizione: "Emessi"
    },
    { 
      id: 3,
      cDescrizione: "Con fattura"
    },
  ];

  eFornitori = [];

  eVeicoli = [];

  eAttrezzature = [];

  eComplessivoRiparato = [];

  eServizi = [];

  eEnti = [];

  t_statoscadenza = [];

  t_tipointerventi = [];

  

  // Variabili
  tab = null;
  veicAttrezSel = "Veicolo";
  buonoFatturaSel = "Con Fattura";
  enteSel = null;

  isAlertDetailVisible = false;
  isCreatedCorrectly = false;
  isNuovoVeicEnabled = false;
  isNuovaAttrezEnabled = false;

  messaggioAlert = "";
  alertType = "error";

  radiosFornitori = "1";
  radiosBuoni = "1";
  radiosScadenze = "1";
  radiosVeicoli = "1";
  radiosAttrezzature = "1";
  radiosRifornimenti = "1";
  radiosNotifiche = "1";
  radiosPrenotazioni = "1";

  // Report fornitori
  f1_annoDa = null;
  f1_annoAl = null;
  f1_fornitore = null;
  f1_ente = null;

  f3_anno = 1990;
  f3_id_stato_buono = null;
  f3_ente = null;

  // Report buoni - interventi di manut
  b1_soloBuoniConFattura = false;
  b1_tipoIntervento = null;
  b1_veicolo = null;
  b1_attrez = null;
  b1_fornitore = null;
  b1_data_dal = null;
  b1_data_al = null;
  b1_ente = null;

  // Report Buoni - Elenco Buoni
  b2_veicolo = null;
  b2_attrez = null;
  b2_fornitore = null;
  b2_data_dal = null;
  b2_data_al = null;
  b2_ente = null;
  b2_stato_buono = null;

  // Report buoni - Lista interventi
  b3_tipoIntervento = null;
  b3_veicolo = null;
  b3_attrez = null;
  b3_fornitore = null;
  b3_data_dal = null;
  b3_data_al = null;
  b3_ente = null;

  // Report Buoni - Conteggi
  b4_ente = null;
  b4_data_dal = null;
  b4_data_al = null;
  b4_statoBuono = null;
  b4_servizio = null;

  // Report Scadenze
  s1_data_al = null;
  s1_data_dal = null;
  s1_ente = null;
  s2_data_al = null;
  s2_data_dal = null;
  s2_ente = null;
  s3_data_al = null;
  s3_data_dal = null;
  s3_ente = null;

  // Veicoli
  v1_data_al = null;
  v1_data_dal = null;
  v1_veicolo = null;
  v1_ente = null;

  v2_data_al = null;
  v2_data_dal = null;
  v2_verifica_rif = false;

  v3_data_al = null;
  v3_data_dal = null;
  v3_ente = null;

  v4_data_al = null;
  v4_data_dal = null;
  v4_veicolo = null;
  v4_converti = false;
  v4_ente = null;

  v6_data_al = null;
  v6_data_dal = null;
  v6_ente = null;

  // Prenotaizoni
  p1_data_al = null;
  p1_data_dal = null;


  /// ******************************
  // Funzionalità / Metodi
  /// ******************************
  created() {
    let verifyLoadingPage = Utils.verifyLoadingPage(this.$store, "R_FORNITORI");
    switch (verifyLoadingPage)
    {
      case 0: // All OK
        this.isCreatedCorrectly = true
        this.getTabSelected("Fornitori");
        this.isNuovoVeicEnabled = Utils.isPageEnabled(this.$store, "W_BUONI_VEICOLI");
        this.isNuovaAttrezEnabled = Utils.isPageEnabled(this.$store, "W_BUONI_ATTREZZ");
        if (this.isNuovoVeicEnabled && this.isNuovaAttrezEnabled)
          this.reportTabs = ["Fornitori", "Buoni", "Scadenze", "Veicoli", "Notifiche", "Prenotazioni"];
        else if (this.isNuovoVeicEnabled && !this.isNuovaAttrezEnabled)
          this.reportTabs = ["Fornitori", "Buoni", "Scadenze", "Veicoli", "Notifiche", "Prenotazioni"];
        else if (!this.isNuovoVeicEnabled && this.isNuovaAttrezEnabled)
          this.reportTabs = ["Fornitori", "Buoni", "Scadenze", "Notifiche"];
        break;
      
      case 1: // Utente non loggato
        AuthService.logout();
        break;

      case 2: // Ente non selezionato
        this.$router.push({ name: "Dashboard" });
        break;

      case 3: // Permesso alla pagina non presente
        this.$router.push({ name: "Dashboard" });
    }

  }

  getTabSelected(tabName) {
    switch (tabName)
    {
      case "Fornitori":
        this.onRadioChangedFornitori();
        break;
      case "Buoni":
        this.onRadioChangedBuoni();
        break;
      case "Scadenze":
        this.onRadioChangedScadenze();
        break;
      case "Veicoli":
        this.onRadioChangedVeicoli();
        break;
      case "Attrezzature":
        break;
      case "Rifornimenti":
        break;
      case "Notifiche":
        break;
    }
  }

  onRadioChangedFornitori() {
    var enteId = this.$store.state.enteSel.id;
    if (enteId < 1)
    {
        this.alertType = "error";
        this.messaggioAlert = i18n.t("erroreSelezioneEnte");
        this.showHide_alertDetail(8000);
        return;
    }

    this.loadEnteAndLinkObjects(enteId);
    switch (this.radiosFornitori)
    {
      case "1": // Budget
        this.loadFornitori();
        break;
      case "2": // Residui
        this.loadFornitori();
        break;
      case "3": // Spese Manutenzione
        // Niemte
        break;
    }
  }

  onRadioChangedBuoni() {
    var enteId = this.$store.state.enteSel.id;
    if (enteId < 1)
    {
        this.alertType = "error";
        this.messaggioAlert = i18n.t("erroreSelezioneEnte");
        this.showHide_alertDetail(8000);
        return;
    }    

    this.loadFornitori();
    this.loadEnteAndLinkObjects(enteId);
    switch (this.radiosBuoni)
    {
      case "1": // Interventi di Manutenzione
        this.onVeicoliAttrezzaturaChange();
        this.loadTInterventoTipo();
        break;
      case "2": // Elenco Buoni
        this.onVeicoliAttrezzaturaChange();
        break;
      case "3": // Lista Interventi
        this.onVeicoliAttrezzaturaChange();
        this.loadTInterventoTipo();
        break;
      case "4": // Conteggio Buoni
        // Niemte
        break;
    }
  }

  onRadioChangedScadenze() {
    this.loadEnteAndLinkObjects(enteId);
    switch (this.radiosScadenze)
    {
      case "1": // Interventi di Manutenzione
        break;
      case "2": // Elenco Buoni
        break;
      case "3": // Lista Interventi
        this.loadTScadenzaStato();
        break;
    }
  }

  onRadioChangedVeicoli() {
    var enteId = this.$store.state.enteSel.id;
    if (enteId < 1)
    {
        this.alertType = "error";
        this.messaggioAlert = i18n.t("erroreSelezioneEnte");
        this.showHide_alertDetail(8000);
        return;
    }     
    this.loadEnteAndLinkObjects(enteId);

    switch (this.radiosVeicoli)
    {
      case "1": // Costi Automezzo
        this.loadVeicoli(enteId, false);
        break;

      case "2": // 
        break;

      case "3": // Costi Capitoli Spesa
        // nulla
        break;

      case "4": // Consumi Automezzi
        break;

      case "5": // 
        break;
      case "6": // Consumi Capitoli Spesa
        break;

      case "7": // 
        break;
    }
  }

  onRadioChangedAttrezzature() {
    // TODO
  }

  onRadioChangedRifornimenti() {
    // TODO
  }

  onRadioChangedNotifiche() {
    switch (this.radiosNotifiche)
    {
      case "1": // 
        break;
    }
  }

  onRadioChangedPrenotazioni() {
    switch (this.radiosPrenotazioni)
    {
      case "1": // 
        break;
    }
  }

  onGeneraReportClick() {
    // Fare tutte le casistiche
    switch (this.tab)
    {
      case 0: //"Fornitori":
        this.generaReportFornitori();
        break;
      case 1: //"Buoni":
        this.generaReportBuoni();
        break;
      case 2: //"Scadenze":
        this.generaReportScadenze();
        break;
      case 3: //"Veicoli":
        this.generaReportVeicoli();
        break;
        /*
      case 4: //"Attrezzature":
        this.alertType = "error";
        this.messaggioAlert = i18n.t("erroreReportNonPresente");
        this.showHide_alertDetail(8000);
        break;
      case 5: //"Rifornimenti":
        this.alertType = "error";
        this.messaggioAlert = i18n.t("erroreReportNonPresente");
        this.showHide_alertDetail(8000);
        break;
        */
      case 4: //"Notifiche":
        this.generaReportNotifiche();
        break;
      case 5: //"Prenotazioni":
        this.generaReportPrenotazioni();
        break;
    }

  }

  onVeicoliAttrezzaturaChange() {
    var enteId = this.$store.state.enteSel.id;
    if (this.veicAttrezSel == "Veicolo")
    {
      this.loadVeicoli(enteId, false);
    }
    else if (this.veicAttrezSel == "Attrezzatura")
    {
      this.loadAttrezzature(enteId, false);
    }
  }

  generaReportFornitori()
  {
    var url = null;
    switch (this.radiosFornitori)
    {
      case "1":
        url = environment.urlSrc + "Report/GeneraBudgetFornitori";

        var enteTmp = -1;
        if (!Utils.isNullOrUndefined(this.f1_ente))
          enteTmp = this.f1_ente.id;
        var fornTmp = -1;
        if (!Utils.isNullOrUndefined(this.f1_fornitore))
          fornTmp = this.f1_fornitore.id;
        var annoDaTmp = -1;
        if (!Utils.isNullOrUndefined(this.f1_annoDa))
          annoDaTmp = this.f1_annoDa;
        var annoAlTmp = -1;
        if (!Utils.isNullOrUndefined(this.f1_annoAl))
          annoAlTmp = this.f1_annoAl;
        window.location =
          url + "?anno_dal=" + annoDaTmp + 
          "&anno_al="+ annoAlTmp + 
          "&id_fornitore="+ fornTmp + 
          "&id_ente="  + enteTmp;
        break;
      case "2":
        break;
      case "3":
        url = environment.urlSrc + "Report/GeneraFornitoriSpeseManutenzione";
        var anno = new Date().getFullYear();
        if (!Utils.isNullOrUndefined(this.f3_anno))
          anno = this.f3_anno;

        var idStatoBuono = 0;
        if (!Utils.isNullOrUndefined(this.f3_id_stato_buono))
          idStatoBuono = this.f3_id_stato_buono.id;
        
        var enteTmp = -1;
        if (!Utils.isNullOrUndefined(this.f3_ente))
          enteTmp = this.f3_ente.id;

        window.location =
          url + "?anno=" + anno + 
          "&id_stato_buono="  + idStatoBuono + 
          "&id_ente="  + enteTmp;
        break;
    }    
  }

  generaReportBuoni()
  {
    var url = null;
    switch (this.radiosBuoni)
    {
      case "1": // Interventi di Manutenzione
        url = environment.urlSrc + "Report/GeneraBuoniListaInterventiManutenzione";
        var dataDal = "1970/01/01";
        if (!Utils.isNullOrUndefined(this.b1_data_dal))
          dataDal = this.b1_data_dal;

        var dataAl = "2040/12/31";
        if (!Utils.isNullOrUndefined(this.b1_data_al))
          dataAl = this.b1_data_al;

        var idForn = 0;
        if (!Utils.isNullOrUndefined(this.b1_fornitore))
          idForn = this.b1_fornitore.id;

        var enteTmp = -1;
        if (!Utils.isNullOrUndefined(this.b1_ente))
          enteTmp = this.b1_ente.id;

        var idVeic = 0;
        var idAttrez = 0;
        var repVeicolo = (this.veicAttrezSel == "Veicolo");
        //let aaa = this.veicAttrezSel;
        if (repVeicolo)
        {
          if (!Utils.isNullOrUndefined(this.b1_veicolo))
            idVeic = this.b1_veicolo.id;
          else
            idVeic = 0;
          idAttrez = 0;
          //repVeicolo = true;
        }
        else
        {
            idVeic = 0;
            if (!Utils.isNullOrUndefined(this.b1_attrez))
              idAttrez = this.b1_attrez.id;
            else
              idAttrez = 0;
            //repVeicolo = false;
        }

        var tipoInterv = 0;
        if (!Utils.isNullOrUndefined(this.b1_tipoIntervento))
          tipoInterv = this.b1_tipoIntervento.id;
        window.location =
          url + "?repVeicolo=" + repVeicolo +
          "&data_buono_dal=" + dataDal + 
          "&data_buono_al="  + dataAl +
          "&id_forniore=" + idForn + 
          "&id_veicolo=" + idVeic + 
          "&id_attrez=" + idAttrez + 
          "&tipoIntervento=" + tipoInterv + 
          "&id_ente=" + enteTmp + 
          "&solo_buoni_fattura=" + this.b1_soloBuoniConFattura;
        break;

      case "2": // Elenco Buoni
        url = environment.urlSrc + "Report/GeneraListaBuoni";
        var dataDal = "1970/01/01";
        if (!Utils.isNullOrUndefined(this.b2_data_dal))
          dataDal = this.b2_data_dal;

        var dataAl = "2040/12/31";
        if (!Utils.isNullOrUndefined(this.b2_data_al))
          dataAl = this.b2_data_al;

        var idForn = 0;
        if (!Utils.isNullOrUndefined(this.b2_fornitore))
          idForn = this.b2_fornitore.id;

        var idVeic = 0;
        var idAttrez = 0;
        var repVeicolo = (this.veicAttrezSel == "Veicolo");
        //let aaa = this.veicAttrezSel;
        if (repVeicolo)
        {
          if (!Utils.isNullOrUndefined(this.b2_veicolo))
            idVeic = this.b2_veicolo.id;
          else
            idVeic = 0;
          idAttrez = 0;
          //repVeicolo = true;
        }
        else
        {
            idVeic = 0;
            if (!Utils.isNullOrUndefined(this.b2_attrez))
              idAttrez = this.b2_attrez.id;
            else
              idAttrez = 0;
            //repVeicolo = false;
        }
        var statoB = 0;
        if (!Utils.isNullOrUndefined(this.b2_stato_buono))
          statoB = this.b2_stato_buono.id;
        var enteTmp = -1;
        if (!Utils.isNullOrUndefined(this.b1_ente))
          enteTmp = this.b1_ente.id;

        window.location =
          url + "?repVeicolo=" + repVeicolo +
          "&data_dal=" + dataDal + 
          "&data_al="  + dataAl +
          "&id_forniore=" + idForn + 
          "&id_veicolo=" + idVeic + 
          "&id_attrez=" + idAttrez + 
          "&stato_buono=" + statoB + 
          "&id_ente=" + enteTmp;

        break;

      case "3": // Lista interventi
        url = environment.urlSrc + "Report/GeneraListaInterventi";
        var dataDal = "1970/01/01";
        if (!Utils.isNullOrUndefined(this.b3_data_dal))
          dataDal = this.b3_data_dal;

        var dataAl = "2040/12/31";
        if (!Utils.isNullOrUndefined(this.b3_data_al))
          dataAl = this.b3_data_al;

        var idForn = 0;
        if (!Utils.isNullOrUndefined(this.b3_fornitore))
          idForn = this.b3_fornitore.id;

        var idVeic = 0;
        var idAttrez = 0;
        var repVeicolo = (this.veicAttrezSel == "Veicolo");
        if (repVeicolo)
        {
          if (!Utils.isNullOrUndefined(this.b3_veicolo))
            idVeic = this.b3_veicolo.id;
          else
            idVeic = 0;
          idAttrez = 0;
        }
        else
        {
            idVeic = 0;
            if (!Utils.isNullOrUndefined(this.b3_attrez))
              idAttrez = this.b3_attrez.id;
            else
              idAttrez = 0;
        }
        var tipoInterv = 0;
        if (!Utils.isNullOrUndefined(this.b3_tipoIntervento))
          tipoInterv = this.b3_tipoIntervento.id;
        var enteTmp = -1;
        if (!Utils.isNullOrUndefined(this.b3_ente))
          enteTmp = this.b3_ente.id;

        window.location =
          url + "?repVeicolo=" + repVeicolo +
          "&data_buono_dal=" + dataDal + 
          "&data_buono_al="  + dataAl +
          "&id_forniore=" + idForn + 
          "&id_veicolo=" + idVeic +
          "&id_attrez=" + idAttrez + 
          "&id_attrez=" + idAttrez + 
          "&id_ente=" + enteTmp ;
          //"&tipoIntervento=" + tipoInterv;
        break;

      case "4": // Conteggi
        url = environment.urlSrc + "Report/GeneraConteggioBuoniServizi";
        var dataDal = "1970/01/01";
        if (!Utils.isNullOrUndefined(this.b4_data_dal))
          dataDal = this.b4_data_dal;

        var dataAl = "2040/12/31";
        if (!Utils.isNullOrUndefined(this.b4_data_al))
          dataAl = this.b4_data_al;

        var statoB = 0;
        if (!Utils.isNullOrUndefined(this.b4_statoBuono))
          statoB = this.b4_statoBuono.id;

        var serviz = 0;
        if (!Utils.isNullOrUndefined(this.b4_servizio))
          serviz = this.b4_servizio.id;
        var enteTmp = -1;
        if (!Utils.isNullOrUndefined(this.b4_ente))
          enteTmp = this.b4_ente.id;

        window.location =
          url + "?data_dal=" + dataDal + 
          "&data_al="  + dataAl +
          "&id_ente=" + enteTmp + 
          "&stato_buono=" + statoB + 
          "&id_servizio=" + serviz;        
        break;
    }    
  }

generaReportScadenze()
  {
    var url = null;
    switch (this.radiosScadenze)
    {
      case "1": // Aperte
        url = environment.urlSrc + "Report/GeneraScadenzeAperte";
        var dataDal = "1970/01/01";
        if (!Utils.isNullOrUndefined(this.s1_data_dal))
          dataDal = this.s1_data_dal;

        var dataAl = "2040/12/31";
        if (!Utils.isNullOrUndefined(this.s1_data_al))
          dataAl = this.s1_data_al;

        var enteTmp = -1;
        if (!Utils.isNullOrUndefined(this.s1_ente))
          enteTmp = this.s1_ente.id;

        window.location =
          url + "?data_dal=" + dataDal + 
          "&data_al="  + dataAl +
          "&id_ente=" + enteTmp;              
        break;

      case "2": // Lista Collaudi
        url = environment.urlSrc + "Report/GeneraScadenzeCollaudi";
        var dataDal = "1970/01/01";
        if (!Utils.isNullOrUndefined(this.s2_data_dal))
          dataDal = this.s2_data_dal;

        var dataAl = "2040/12/31";
        if (!Utils.isNullOrUndefined(this.s2_data_al))
          dataAl = this.s2_data_al;

        var enteTmp = -1;
        if (!Utils.isNullOrUndefined(this.s2_ente))
          enteTmp = this.s2_ente.id;

        window.location =
          url + "?data_dal=" + dataDal + 
          "&data_al="  + dataAl +
          "&id_ente=" + enteTmp;              
        break;

      case "3": // Lista Generali
        url = environment.urlSrc + "Report/GeneraListaScadenzeGenerali";
        var dataDal = "1970/01/01";
        if (!Utils.isNullOrUndefined(this.s3_data_dal))
          dataDal = this.s3_data_dal;

        var dataAl = "2040/12/31";
        if (!Utils.isNullOrUndefined(this.s3_data_al))
          dataAl = this.s3_data_al;

        var enteTmp = -1;
        if (!Utils.isNullOrUndefined(this.s3_ente))
          enteTmp = this.s3_ente.id;

        window.location =
          url + "?data_dal=" + dataDal + 
          "&data_al="  + dataAl +
          "&id_ente=" + enteTmp;
        break;
    }    
  }

generaReportVeicoli()
  {
    var url = null;
    switch (this.radiosVeicoli)
    {
      case "1": // Costi Automezzi
        url = environment.urlSrc + "Report/GeneraCostiAutomezzo";
        var dataDal = "1970/01/01";
        if (!Utils.isNullOrUndefined(this.v1_data_dal))
          dataDal = this.v1_data_dal;

        var dataAl = "2040/12/31";
        if (!Utils.isNullOrUndefined(this.v1_data_al))
          dataAl = this.v1_data_al;

        var veicTmp = -1;
        if (!Utils.isNullOrUndefined(this.v1_veicolo))
          veicTmp = this.v1_veicolo.id;

        var enteTmp = -1;
        if (!Utils.isNullOrUndefined(this.v1_ente))
          enteTmp = this.v1_ente.id;

        window.location =
          url + "?data_dal=" + dataDal + 
          "&data_al="  + dataAl +
          "&id_veicolo="  + veicTmp +
          "&id_ente=" + enteTmp;
        break;

      case "2":
        break;

      case "3": // Costi Capitoli Spesa
        url = environment.urlSrc + "Report/GeneraCostiAutomezzoCapSpesa";
        var dataDal = "1970/01/01";
        if (!Utils.isNullOrUndefined(this.v3_data_dal))
          dataDal = this.v3_data_dal;

        var dataAl = "2040/12/31";
        if (!Utils.isNullOrUndefined(this.v3_data_al))
          dataAl = this.v3_data_al;

        var enteTmp = -1;
        if (!Utils.isNullOrUndefined(this.v3_ente))
          enteTmp = this.v3_ente.id;

        window.location =
          url + "?data_dal=" + dataDal + 
          "&data_al="  + dataAl +
          "&id_ente=" + enteTmp;
        break;

      case "4": // Consumi Automezzi
        url = environment.urlSrc + "Report/GeneraRiepilogoConsumiAuto";
        var dataDal = "1970/01/01";
        if (!Utils.isNullOrUndefined(this.v4_data_dal))
          dataDal = this.v4_data_dal;

        var dataAl = "2040/12/31";
        if (!Utils.isNullOrUndefined(this.v4_data_al))
          dataAl = this.v4_data_al;

        var veicTmp = -1;
        if (!Utils.isNullOrUndefined(this.v4_veicolo))
          veicTmp = this.v4_veicolo.id;

        var enteTmp = -1;
        if (!Utils.isNullOrUndefined(this.v4_ente))
          enteTmp = this.v4_ente.id;

        window.location =
          url + "?data_dal=" + dataDal + 
          "&data_al="  + dataAl +
          "&id_veicolo="  + veicTmp +
          "&converti="  + this.v4_converti +
          "&id_ente=" + enteTmp;
        break;

      case "5":
        break;

      case "6": // Consumi Capitoli Spesa
        url = environment.urlSrc + "Report/GeneraConsumiAutomezziCapSpesa";
        var dataDal = "1970/01/01";
        if (!Utils.isNullOrUndefined(this.v6_data_dal))
          dataDal = this.v6_data_dal;

        var dataAl = "2040/12/31";
        if (!Utils.isNullOrUndefined(this.v6_data_al))
          dataAl = this.v6_data_al;

        var enteTmp = -1;
        if (!Utils.isNullOrUndefined(this.v6_ente))
          enteTmp = this.v6_ente.id;

        window.location =
          url + "?data_dal=" + dataDal + 
          "&data_al="  + dataAl +
          "&id_ente=" + enteTmp;
        break;

      case "7":
        break;
    }    
  }

  generaReportNotifiche()
  {
    var url = null;
    switch (this.radiosPrenotazioni)
    {
      case "1":
        var userId = this.$store.state.user.id;
        ExcelService.generaExcelNotifiche(
          userId,
          function(data, resp) {
              const blob = new Blob([data]);
              const el = document.createElement("a");
              el.href = window.URL.createObjectURL(blob);
              const regex = /filename=(?<filename>[^,;]+);/g;
              const match = regex.exec(resp.headers['content-disposition']);
              let filename = ""; // or any other value you consider default
              if (typeof match !== 'undefined' && match !== null) {
                  filename = match.groups['filename'];
              }
              el.download = filename;
              el.click();
            },
            (fail) => {
              alert(fail);
            },
        )          
        break;
    }    
  }

  generaReportPrenotazioni()
  {
    var url = null;
    switch (this.radiosPrenotazioni)
    {
      case "1":
        var dataDal = "2010/01/01";
        if (!Utils.isNullOrUndefined(this.p1_data_dal))
          dataDal = this.p1_data_dal;
        else
        {
            this.alertType = "error";
            this.messaggioAlert = i18n.t("erroreReportPrenotDataDal");
            this.showHide_alertDetail(8000);
            return;
        }

        var dataAl = "2040/12/31";
        if (!Utils.isNullOrUndefined(this.p1_data_al))
          dataAl = this.p1_data_al;
        else
        {
            this.alertType = "error";
            this.messaggioAlert = i18n.t("erroreReportPrenotDataAl");
            this.showHide_alertDetail(8000);
            return;
        }
        if (dataAl < dataDal)
        {
            this.alertType = "error";
            this.messaggioAlert = i18n.t("erroreReportPrenotDataDalAl");
            this.showHide_alertDetail(8000);
            return;
        }

        var enteId = this.$store.state.enteSel.id;
        var storico = true;
        ExcelService.generaExcelPrenotazioni(
          enteId,
          storico,
          dataDal,
          dataAl,
          function(data, resp) {
              const blob = new Blob([data]);
              const el = document.createElement("a");
              el.href = window.URL.createObjectURL(blob);
              const regex = /filename=(?<filename>[^,;]+);/g;
              const match = regex.exec(resp.headers['content-disposition']);
              let filename = ""; // or any other value you consider default
              if (typeof match !== 'undefined' && match !== null) {
                  filename = match.groups['filename'];
              }
              el.download = filename;
              el.click();
            },
            (fail) => {
              alert(fail);
            },
        )          
        break;
    }    
  }

  showHide_alertDetail(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertDetailVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertDetailVisible = false;
      //console.log("hide alert after " + millisecondInterval/1000 + " seconds");
    }, millisecondInterval);
  }

  /// ******************************
  // DATABASE
  /// ******************************

  loadFornitori() {
    var self = this;

    FornitoriService.getFornitori(
      (resp) => {
        console.log("GetFornitori");
        console.log(resp);

        self.eFornitori = resp;
      },
      (err) => {
        console.log("Errore GetFornitori");
        console.log(err);
      }
    );
  }
  
  loadVeicoli(enteId, storico) {
    var self = this;

    VeicoliService.getVeicoli(
      enteId,
      storico,
      (resp) => {
        console.log("Veicoli:");
        console.log(resp);

        self.eVeicoli = resp;
      },
      (err) => {
        console.log("Error Veicoli:");
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = i18n.t("erroreCaricamentoVeicoli");
        self.showHide_alertGrid(5000);
      }
    );
  }

  loadAttrezzature(enteId, storico) {
    var self = this;

    AttrezzatureService.getAttrezzature(
      enteId,
      storico,
      (resp) => {
        console.log("Attrezzature:");
        console.log(resp);

        self.eAttrezzature = resp;
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = i18n.t("getAttrezzature");
        self.showHide_alertGrid(5000);
      }
    );
  }

  loadTInterventoTipo() {
    var self = this;

    TipologicheService.getTInterventoTipo(
      (resp) => {
        console.log("GetTInterventoTipo");
        console.log(resp);

        self.t_tipointerventi = resp;
      },
      (err) => {
        console.log("Errore GetTInterventoTipo");
        console.log(err);
      }
    );
  }

  loadTScadenzaStato() {
    var self = this;

    TipologicheService.getTScadenzaStato(
      (resp) => {
        console.log("GetTScadenzaStato");
        console.log(resp);

        self.t_statoscadenza = resp;
      },
      (err) => {
        console.log("Errore GetTScadenzaStato");
        console.log(err);
      }
    );
  }

  loadEnteAndLinkObjects(idEnte) {
    var self = this;

    EntiService.getEnteAndLinkObjects(
      idEnte,
      (resp) => {
        console.log("GetEnteAndLinkObjects");
        console.log(resp);
        self.eServizi = resp.serviziEnte;
        self.eEnti = [resp];
      },
      (err) => {
        console.log("Errore GetEnteAndLinkObjects");
        console.log(err);
      }
    );
  }
}
</script>