import Utils from '@/utils/Utils';
import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'Report/';

class ReportService {
    generaListaInterventiManutenzione(repVeicolo, data_buono_dal, data_buono_al, id_forniore, id_veicolo, id_attrez, tipoIntervento, solo_buoni_fattura, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("repVeicolo", repVeicolo);
        parametri.append("data_buono_dal", data_buono_dal);
        parametri.append("data_buono_al", data_buono_al);
        parametri.append("id_forniore", id_forniore);
        parametri.append("id_veicolo", id_veicolo);
        parametri.append("id_attrez", id_attrez);
        parametri.append("tipoIntervento", tipoIntervento);
        parametri.append("solo_buoni_fattura", solo_buoni_fattura);

        UtilsRequest.get(API_URL + "GeneraBuoniListaInterventiManutenzione", parametri,
        successFn,
        failFn
        );
    }

    generaFornitoriSpeseManutenzione(anno, id_stato_buono, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("anno", anno);
        parametri.append("id_stato_buono", id_stato_buono);

        UtilsRequest.get(API_URL + "GeneraFornitoriSpeseManutenzione", parametri,
        successFn,
        failFn
        );
    }

}

export default new ReportService();


